import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
        <div id="colorlib-about">
            <div className="container">
                <div className="row text-center">
                    <h2 className="bold">About</h2>
                </div>
                <div className="row">
                    <div className="col-md-5 animate-box">
                        <div className="owl-carousel3">
                            <div className="item">
                                <img className="img-responsive about-img" src="images/a1.jpg" alt="html5 bootstrap template by colorlib.com" />
                            </div>
                            <div className="item">
                                <img className="img-responsive about-img" src="images/a2.jpg" alt="html5 bootstrap template by colorlib.com" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-md-push-1 animate-box">
                        <div className="about-desc">
                            <div className="owl-carousel3">
                                <div className="item">
                                    <h2><span>Ahmad</span><span>Kharbat</span></h2>
                                </div>
                                <div className="item">
                                    <h2><span>A little about me</span></h2>
                                </div>
                            </div>
                            <div className="desc">
                                <div className="rotate">
                                    <h2 className="heading">About</h2>
                                </div>
                                <p>Technical leader with passion towards creativity and innovation through hardware and software engineering. With 15+ years of experience, I have a deep understanding of the tech industry and the market needs. I develop ideas and solutions for various sectors and industries.</p>
                                <p className="colorlib-social-icons">
                                    <a target="_blank" href="https://www.linkedin.com/in/ahmadkharbat/"><i className="icon-linkedin4"></i></a>
                                    <a target="_blank" href="https://www.fb.com/kharbat/"><i className="icon-facebook4"></i></a>
                                </p>
                                <p><a href="#" className="btn btn-primary btn-outline">Contact Me!</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default About;
