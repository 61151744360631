import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (

			<footer>
			<div id="footer">
				<div className="container">
					<div className="row">
						<div className="col-md-4 col-pb-sm">
							<div className="row">
								<div className="col-md-10">
									<h2>Let's Talk</h2>
									<p>If you need any kind of tech service or consultancy don't hesitate to contact me at:</p>
									<p>kharbat[@]gmail.com</p>
									<p className="colorlib-social-icons">
                                    <a target="_blank" href="https://www.linkedin.com/in/ahmadkharbat/"><i className="icon-linkedin4"></i></a>
                                    <a target="_blank" href="https://www.fb.com/kharbat/"><i className="icon-facebook4"></i></a>
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-4 col-pb-sm">
							<h2>Latest Tweets</h2>
							<div className="f-entry">
								<a href="#" className="featured-img" style= {{ backgroundImage: "url(images/img-1.jpg)" }} ></a>
								<div className="desc">
									<span>28 Jan 2018</span>
									<h3><a target="_blank" href="https://twitter.com/kharbat/status/957520428565659650">We’re here at the #saudiiot check us out @manzelexpress #SaudiIoT2018 #Saudi_IoT</a></h3>
								</div>
							</div>
							<div className="f-entry">
								<a href="#" className="featured-img" style= {{ backgroundImage: "url(images/img-2.jpg)" }} ></a>
								<div className="desc">
									<span>15 May 2018</span>
									<h3><a target="_blank" href="https://twitter.com/alriyadhdaily/status/996412472629321730">Retal Residence adopts Manzel Express as a state-of-the-art major prop...</a></h3>
								</div>
							</div>
							<div className="f-entry">
								<a href="#" className="featured-img" style= {{ backgroundImage: "url(images/img-3.jpg)" }} ></a>
								<div className="desc">
									<span> 29 Jan 2018</span>
									<h3><a href="https://twitter.com/manzelexpress/status/958082289111654401">Come check our booth in the #SaudiIoT2018 #Manzel_Express</a></h3>
								</div>
							</div>
						</div>
						<div className="col-md-4 col-pb-sm">
							<h2>Newsletter</h2>
							<p>Submit your email to be part of my newsletter about latest researches, products and ideas</p>
							<div className="subscribe text-center">
								<div className="form-group">
									<input type="text" className="form-control text-center" placeholder="Enter Email address" />
									<input type="submit" value="Subscribe" className="btn btn-primary btn-custom" />
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12 text-center">
							<p>
								Copyright &copy; { new Date().getFullYear() }  All rights reserved | This template is made with <i className="icon-heart4" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib</a>, and customized by me using ReactJS
							</p>
						</div>
					</div>
				</div>
			</div>
		</footer>       
    );
  }
}

export default Footer;
