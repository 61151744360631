import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Home from './components/home/Home';
import Services from './components/home/Services';


class App extends Component {
  render() {
    return (
      <Home />
    );
  }
}

export default App;
