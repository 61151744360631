import React, { Component } from 'react';

class Testimony extends Component {
  render() {
    return (
 
		<div id="colorlib-testimony">
			<div class="container">
				<div class="row text-center">
					<h2 class="bold">Testimonies</h2>
				</div>
				<div class="row">
					<div class="col-md-12 col-md-offset-0 text-center animate-box intro-heading">
						<span>Testimonies</span>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<div class="rotate">
							<h2 class="heading">Says</h2>
						</div>
					</div>
				</div>
				<div class="row animate-box">
					<div class="owl-carousel">
						<div class="item">
							<div class="col-md-12 text-center">
								<div class="testimony">
									<p className="text-center">
										<img style={{ width: "100px", display: "inline-block" }} className="img-circle" src="images/kris.jpg" />
									</p>
									<blockquote>
										<p>Ahmad is a the kind of dynamic collaborator you want on your team. He is a fast learn in any type of environment. Whether it was architecture planning or new coding and platform conventions, I was always impressed with his ability to rise to the occasion and excel at the task.</p>
										<p>Additionally, Ahmad assisted me in timeline and project planning. His thoughtful insight helped align our goals with real world outcomes. He also proved to be a great mentor with his peers. If a roadblock occurred during a cycle, he answered with the fortitude to resolve, test and double validate updates. Ahmad will be a great addition to your team.</p>
										<span>" &mdash; Kris Frazier, SVP, Experience Design Director at CitiBank</span>
									</blockquote>
								</div>
							</div>
						</div>
						<div class="item">
							<div class="col-md-12 text-center">
								<div class="testimony">
									<p className="text-center">
										<img style={{ width: "100px", display: "inline-block" }} className="img-circle" src="images/nat.jpg" />
									</p>
									<blockquote>
										<p>Ahmad and I worked together on a very lean development team where having skills in many areas is critical and development abilities alone are not enough. Working with him in this environment I got to see a lot of his other skills shine; not only is he a great developer he is also a strategist, creative problem solver and a businessman. The ability to see the big picture, brainstorm new strategies, and learn new technologies & frameworks came naturally to him. Any team would be lucky to have Ahmad, he’s easy to work with and a great teammate.</p>
										<span>" &mdash; Nat Harding, Senior Developer at Verizon</span>
									</blockquote>
								</div>
							</div>
						</div>
						<div class="item">
							<div class="col-md-12 text-center">
								<div class="testimony">
									<p className="text-center">
										<img style={{ width: "100px", display: "inline-block" }} className="img-circle" src="images/kayla.jpg" />
									</p>								
									<blockquote>
										<p>Ahmad was such a pleasure to work with. When he joined our team in 2013, I was immediately impressed with his knowledge and proficiency in Drupal. He adapted quickly to our project management style and continually showed growth in a fast-paced, deadline-driven environment. He is smart, innovative, and extremely talented. I have and will continue to recommend Ahmad to anyone that needs guidance on a Drupal project.</p>
										<span>" &mdash; Kayla Wren, User Research Lead at Sabre Corporation</span>
									</blockquote>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
        
        );
  }
}

export default Testimony;
