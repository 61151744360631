import React, { Component } from 'react';

class Blog extends Component {
  render() {
    return (


		<div id="colorlib-blog">
			<div class="container">
				<div class="row text-center">
					<h2 class="bold">Blog</h2>
				</div>
				<div class="row">
					<div class="col-md-12 col-md-offset-0 text-center animate-box intro-heading">
						<span>Blog</span>
						<h2>Read Our Case</h2>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<div class="rotate">
							<h2 class="heading">Our Blog</h2>
						</div>
					</div>
				</div>
				<div class="row animate-box">
					<div class="owl-carousel1">
						<div class="item">
							<div class="col-md-12">
								<div class="article">
									<a href="blog.html" class="blog-img">
										<img class="img-responsive" src="images/img-1.jpg" alt="html5 bootstrap by colorlib.com" />
										<div class="overlay"></div>
										<div class="link">
											<span class="read">Read more</span>
										</div>
									</a>
									<div class="desc">
										<span class="meta">15, Feb 2018</span>
										<h2><a href="blog.html">A Japanese Constellation</a></h2>
										<p>When she reached the first hills of the Italic Mountains, she had a last view back on the skyline of her hometown Bookmarksgrove</p>
									</div>
								</div>
							</div>
						</div>
						<div class="item">
							<div class="col-md-12">
								<div class="article">
									<a href="blog.html" class="blog-img">
										<img class="img-responsive" src="images/img-2.jpg" alt="html5 bootstrap by colorlib.com" />
										<div class="overlay"></div>
										<div class="link">
											<span class="read">Read more</span>
										</div>
									</a>
									<div class="desc">
										<span class="meta">15, Feb 2018</span>
										<h2><a href="blog.html">A Japanese Constellation</a></h2>
										<p>When she reached the first hills of the Italic Mountains, she had a last view back on the skyline of her hometown Bookmarksgrove</p>
									</div>
								</div>
							</div>
						</div>
						<div class="item">
							<div class="col-md-12">
								<div class="article">
									<a href="blog.html" class="blog-img">
										<img class="img-responsive" src="images/img-3.jpg" alt="html5 bootstrap by colorlib.com" />
										<div class="overlay"></div>
										<div class="link">
											<span class="read">Read more</span>
										</div>
									</a>
									<div class="desc">
										<span class="meta">15, Feb 2018</span>
										<h2><a href="blog.html">A Japanese Constellation</a></h2>
										<p>When she reached the first hills of the Italic Mountains, she had a last view back on the skyline of her hometown Bookmarksgrove</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

  );
  }
}

export default Blog;
