import React, { Component } from 'react';

class Work extends Component {
  render() {
    return (

		<div id="colorlib-work">
			<div className="container">
				<div className="row text-center">
					<h2 className="bold">Works</h2>
				</div>
				<div className="row">
					<div className="col-md-12 col-md-offset-0 text-center animate-box intro-heading">
						<span>Portfolio</span>
						<h2>Works & Projects</h2>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="rotate">
							<h2 className="heading">Portfolio</h2>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="work-entry animate-box">
						<a target="_blank" href="http://beloandco.com" className="work-img" style={{ backgroundImage: "url(images/work-1.jpg)" }} >

							</a>
							<div className="col-md-4 col-md-offset-4">
								<div className="desc">
									<p>2018 - Present as a Sr. Software Architect and a Team leader, I helped Belo+Company move towards AWS and implement a proper Agile CI/CD process, with focus on automated testing and automated release.</p>
									<p className="read"><a target="_blank" href="http://beloandco.com">Belo+Company</a></p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<div className="work-entry animate-box">
							<a target="_blank" href="http://manzelexpress.com" className="work-img" style={{ backgroundImage: "url(images/work-2.jpg)" }} >
								<div className="display-t">
									<div className="work-name">
										<img src="images/manzel.png" />
									</div>
								</div>
							</a>
							<div className="col-md-4 col-md-offset-4">
								<div className="desc">
									<p>2015 - Present, as co-founder and a CTO of Manzel Express, I helped development an enterprise SAAS solution for gated communnities and property managers where they manage their units, tenants, leases and much more.</p>
									<p className="read"><a target="_blank" href="http://manzelexpress.com">Manzel Express</a></p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<div className="work-entry animate-box">
							<a target="_blank" href="https://www.verizon.com/about/" className="work-img" style={{ backgroundImage: "url(images/work-3.jpg)" }} >
								<div className="display-t">
									<div className="work-name">
										<h2>Verizon</h2>
									</div>
								</div>
							</a>
							<div className="col-md-4 col-md-offset-4">
								<div className="desc">
									<p>2017 - 2018 As a Solution Architect at Verizon, I helped launching their new Venture Exponent, where their clients have self encapsulated portals running on demand using Microsoft Azure and Docker Microservices.</p>
									<p className="read"><a target="_blank" href="https://www.verizon.com/about/">Verizon. Dallas, TX</a></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
        );
  }
}

export default Work;
