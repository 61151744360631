import React, { Component } from 'react';

import Navigation from '../common/Navigation';

import Header from '../common/Header';
import Footer from '../common/Footer';

import About from './About';
import Services from './Services';
import Work from './Work';
import Blog from './Blog';
import Testimony from './Testimony';


class Home extends Component {
  render() {
    return (
      <div className="App">
        <Navigation />

        <div id="colorlib-page">
          <Header />
          <About />
          <Services />
          <Work />
          <Testimony />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Home;
