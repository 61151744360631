import React, { Component } from 'react';

class Services extends Component {
  render() {
    return (
        <div id="colorlib-services">
        <div className="container">
            <div className="row text-center">
                <h2 className="bold">Expertise</h2>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="services-flex">
                        <div className="one-third">
                            <div className="row">
                                <div className="col-md-12 col-md-offset-0 animate-box intro-heading">
                                    <span>Expertise</span>
                                    <h2>Highly diversified with focus on the following</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="rotate">
                                        <h2 className="heading">Expertise</h2>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="services animate-box">
                                        <h3>1 - Solution Arhitecture</h3>
                                        <ul>
                                            <li>Amazon Web Services</li>
                                            <li>Microsoft Azure</li>
                                            <li>Docker Microservices</li>
                                            <li>Automation and CI/CD</li>
                                        </ul>
                                    </div>
                                    <div className="services animate-box">
                                        <h3>3 - Backend Development</h3>
                                        <ul>
                                            <li>PHP / MySQL / Postgres</li>
                                            <li>Drupal 6, 7, 8</li>
                                            <li>Yii2</li>
                                            <li>Laravel</li>
                                            <li>Node JS / MongoDB</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="services animate-box">
                                        <h3>2 - Frontend Development</h3>
                                        <ul>
                                            <li>HTML / CSS</li>
                                            <li>Vanilla Javascript</li>
                                            <li>JQuery</li>
                                            <li>ReactJS</li>
                                            <li>Ionic 4</li>
                                        </ul>
                                    </div>
                                    <div className="services animate-box">
                                        <h3>4 - Hardware</h3>
                                        <ul>
                                            <li>Amazon Alexa</li>
                                            <li>Raspberry Pi</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="one-forth services-img" style={{ backgroundImage: "url(images/services-img-1.jpg)" }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }
}

export default Services;
